<!--
 * @Descripttion:
 * @Author: liuxia
 * @Date: 2022-06-09 10:36:54
 * @LastEditors: liuxia
 * @LastEditTime: 2022-06-09 11:11:30
-->
<template>
  <div class="adjustment-price-dialog">
    <a-modal class="init-dialog" :visible.sync="visible">
      <template #title>
        <div class="modal-head">
          <div class="title">系统提示</div>
        </div>
      </template>
      <div class="init-text">
        <icon-font class="icon" type="icon-zhuyi"></icon-font
        >此操作导致总价结果金额偏离，建议人工调整价格。
      </div>
      <template slot="footer">
        <div>
          <a-checkbox @change="onChange">本次登录不再提示</a-checkbox>
          <a-button type="primary" :loading="loading" @click="cancel"
            >知道了</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'adjustmentPriceDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cancel() {
      console.log('111111');
    },
    onChange() {
      console.log('222222');
    },
  },
});
</script>

<style scoped></style>
